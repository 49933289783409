/**
 * Date Created: 24-06-2020
 * Author: Nico Richardo
 * Reducers to store & keeping track Vacancy-related data on Redux
 */
import { eActionTypes } from '../enums';
import { KDAVacancyObject } from '../interfaces';

const initialState = {
  g_arrVacancyList: [],
  g_objNewVacancy: new KDAVacancyObject(-1),
  g_objSelectedVacancy: new KDAVacancyObject(-1),
}

export default function (state = initialState, action) {
  switch (action.type) {
    case eActionTypes.FETCH_VACANCY_LIST:
      return {
        ...state,
        g_arrVacancyList: action.payload,
      };
    case eActionTypes.FETCH_VACANCY_BY_ID:
      return {
        ...state,
        g_objSelectedVacancy: action.payload,
      };
    case eActionTypes.UPDATE_VACANCY_BY_ID:
      return {
        ...state,
        g_objSelectedVacancy: action.payload,
      };
    case eActionTypes.DELETE_VACANCY_BY_ID:
      return {
        ...state,
        g_objSelectedVacancy: action.payload,
      };
    case eActionTypes.CREATE_VACANCY:
      return {
        ...state,
        g_arrVacancyList: action.payload,
      };
    default:
      return state;
  }
}
