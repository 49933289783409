export enum eActionTypes {
  SET_APP_SIDEBAR_STATE = '[Redux] SET_APP_SIDEBAR_STATE',
  SET_USER = '[Redux] SET_USER',
  FETCH_USER = '[Redux] FETCH_USER',

  FETCH_VACANCY_LIST = '[Redux] FETCH_VACANCY_LIST',
  FETCH_VACANCY_BY_ID = '[Redux] FETCH_VACANCY_BY_ID',
  UPDATE_VACANCY_BY_ID = '[Redux] UPDATE_VACANCY_BY_ID',
  DELETE_VACANCY_BY_ID = '[Redux] DELETE_VACANCY_BY_ID',
  CREATE_VACANCY = '[Redux] CREATE_VACANCY',

  FETCH_APPLICANTS_BY_VACANCY_ID = '[Redux] FETCH_APPLICANTS_BY_VACANCY_ID',
  FETCH_APPLICANT_BY_APPLICANT_ID = '[Redux] FETCH_APPLICANT_BY_APPLICANT_ID',
  UPDATE_APPLICANT_BY_APPLICANT_ID = '[Redux] UPDATE_APPLICANT_BY_APPLICANT_ID',
  DELETE_APPLICANT_BY_APPLICANT_ID = '[Redux] DELETE_APPLICANT_BY_APPLICANT_ID',

  FETCH_SERVER_STORAGE_USAGE = '[Redux] FETCH_SERVER_STORAGE_USAGE',
  FETCH_DB_BACKUP_DATA = '[Redux] FETCH_DB_BACKUP_DATA',
  RESTORE_DB_DATA = '[Redux] RESTORE_DB_DATA',
  FLUSH_DB_DATA = '[Redux] FLUSH_DB_DATA',
}

export const eAppSidebarState = {
  SHOW: true,
  HIDE: false,
  RESPONSIVE: 'responsive',
}

export enum eVacancyInputName {
  DATE_CLOSING = 'vacancy-closing',
  TITLE = 'vacancy-title',
  DESCRIPTION = 'vacancy-description',
  SECTION_HEADING = 'vacancy-section-heading',
  SECTION_TEXT = 'vacancy-section-text',
  SECTION_POINTS = 'vacancy-section-points',
  SECTION_POINTS_CHANGE = 'vacancy-section-point-change',
  SECTION_POINTS_DELETE = 'vacancy-section-point-delete',
  SECTION_DELETE = 'vacancy-section-delete',
}

export enum eErrorStatus {
  FILE_CHECKSUM_FAIL = 'fail to generate MD5',
  FILE_CORRUPTED = 'generated MD5 is not same',
  API_CALL_FAIL = 'API call error'
}