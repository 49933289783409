import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './scss/style.scss';
import * as CONFIG from './config.json';
import { refreshToken } from './utils/actions/KDAAppActions';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const KDALogin = React.lazy(() => import('./views/KDALogin/KDALogin'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const KDALayout = React.lazy(() => import('./containers/KDALayout'));

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount = () => {
    const refreshToken = localStorage.getItem(CONFIG.cache_jwt_refresh);
    if (refreshToken) {
      this.props.refreshToken(refreshToken, this.refreshTokenSuccess, this.refreshTokenError);
    }
  }

  refreshTokenSuccess = () => {
    this.setState({});
  }

  refreshTokenError = () => {
    this.setState({});
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="KDALogin" render={props => <KDALogin {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            {this.props.g_bIsAuthenticated
              ? <Route path="/" name="Home" render={props => <KDALayout {...props} />} />
              : <Redirect to="/login" />
            }
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

App.propTypes = {
  refreshToken: PropTypes.func.isRequired,
  g_bIsAuthenticated: PropTypes.bool.isRequired,
  g_objUser: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  g_bIsAuthenticated: state.appStorage.g_bIsAuthenticated,
  g_objUser: state.appStorage.g_objUser,
});

export default connect(mapStateToProps, { refreshToken })(App);
