/**
 * Date Created: 24-06-2020
 * Author: Jose Gunawarman
 * Combines all reducers & storages into one root reducer
 */
import { combineReducers } from 'redux';
import KDAVacancyReducers from './KDAVacancyReducers'
import KDAApplicantReducers from './KDAApplicantReducers'
import KDAAppReducers from './KDAAppReducers';
import KDAServerReducers from './KDAServerReducers'

export default combineReducers({
    appStorage: KDAAppReducers,
    vacancyStorage: KDAVacancyReducers,
    applicantStorage: KDAApplicantReducers,
    serverStorage: KDAServerReducers
});
