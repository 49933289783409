// /**
//  * Date Created: 23-07-2020
//  * Author: Nico Richardo
//  * Reducers to store & keeping track Server-related data on Redux
//  */

import { eActionTypes } from '../enums';
import { KDAServerObject } from '../interfaces';

const initialState = {
    g_objServerStorage: new KDAServerObject(-1),
    g_objBackupData: new KDAServerObject(-1),
}

export default function (state = initialState, action) {
    switch (action.type) {
        case eActionTypes.FETCH_SERVER_STORAGE_USAGE:
            return {
                ...state,
                g_objServerStorage: action.payload,
            };
        case eActionTypes.FETCH_DB_BACKUP_DATA:
            return {
                ...state,
                g_objBackupData: action.payload,
            };
        case eActionTypes.RESTORE_DB_DATA:
            return {
                ...state,
                g_objBackupData: action.payload,
            };
        case eActionTypes.FLUSH_DB_DATA :
            return {
                ...state,
                g_objBackupData: action.payload,
            };
        default:
            return state;
    }
}