/**
 * Date Created: 02-07-2020
 * Author: Jose Gunawarman
 * Reducers to store & keeping track App-level data on Redux, including authentication
 */
import isEmpty from 'lodash/isEmpty';
import * as CONFIG from '../../config.json'
import { eActionTypes, eAppSidebarState } from '../enums';

const initialState = {
  g_eSidebarShow: eAppSidebarState.RESPONSIVE,
  g_bIsAuthenticated: !isEmpty(localStorage.getItem(CONFIG.cache_jwt_access)),
  g_objUser: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case eActionTypes.SET_APP_SIDEBAR_STATE:
      return {
        ...state,
        g_eSidebarShow: action.payload,
      };
    case eActionTypes.SET_USER:
      return {
        ...state,
        g_bIsAuthenticated: !isEmpty(action.payload),
        g_objUser: action.payload,
      }
    default:
      return state;
  }
}
