/**
 * Date Created: 02-07-2020
 * Author: Jose Gunawarman
 * Actions to alter App-level data on Redux, including authentication
 * Fetch all relevant data from backend according to the action type
 */
import axios from 'axios';
import jwt from 'jsonwebtoken';
import * as CONFIG from '../../config.json';
import setAuthorizationHeader from '../setAuthorizationHeader';
import { eActionTypes } from '../enums';


export function setAppSidebarState(eNewState) {
  return function (dispatch) {
    dispatch({
      type: eActionTypes.SET_APP_SIDEBAR_STATE,
      payload: eNewState,
    });
  }
}

function setJwtCache(a_sslKey, a_refreshToken, a_accessToken) {
  localStorage.setItem(CONFIG.cache_ssl_key, a_sslKey);
  localStorage.setItem(CONFIG.cache_jwt_refresh, a_refreshToken);
  localStorage.setItem(CONFIG.cache_jwt_access, a_accessToken);
  setAuthorizationHeader(a_sslKey, a_accessToken);
}

function clearJwtCache() {
  localStorage.removeItem(CONFIG.cache_ssl_key);
  localStorage.removeItem(CONFIG.cache_jwt_refresh);
  localStorage.removeItem(CONFIG.cache_jwt_access);
  setAuthorizationHeader();
}

export function loginUser(a_strUsername, a_strPassword, successCallback, errorCallback) {
  return function (dispatch) {
    const form = new FormData();
    form.append('username', a_strUsername);
    form.append('password', a_strPassword);

    return axios.post(`${CONFIG.http_protocol + CONFIG.backend_host + (CONFIG.backend_port ? `:${CONFIG.backend_port}` : '')}/login/`, form)
      .then((res) => {
        const sslKey = res.headers[CONFIG.api_ssl_header_res];
        const refreshToken = res.data.refresh;
        const accessToken = res.data.access;

        setJwtCache(sslKey, refreshToken, accessToken);
        const user = jwt.decode(accessToken);
        dispatch({
          type: eActionTypes.SET_USER,
          payload: user,
        });

        if (successCallback) {
          successCallback();
        }
      }).catch((err) => {
        console.error(err);

        clearJwtCache();
        dispatch({
          type: eActionTypes.SET_USER,
          payload: {},
        });

        if (errorCallback) {
          if (err.response) {
            errorCallback(err.response.status);
          }
          else {
            errorCallback("500");
          }
        }
      });
  }
}

export function refreshToken(a_token, successCallback, errorCallback) {
  return function (dispatch) {
    if (!a_token) {
      a_token = localStorage.getItem(CONFIG.cache_jwt_refresh);
    }

    const form = new FormData();
    form.append('refresh', a_token);

    return axios.post(`${CONFIG.http_protocol + CONFIG.backend_host + (CONFIG.backend_port ? `:${CONFIG.backend_port}` : '')}/login/refresh/`, form)
      .then((res) => {
        const sslKey = res.headers[CONFIG.api_ssl_header_res];
        const refreshToken = res.data.refresh;
        const accessToken = res.data.access;

        setJwtCache(sslKey, refreshToken, accessToken);
        const user = jwt.decode(accessToken);
        dispatch({
          type: eActionTypes.SET_USER,
          payload: user,
        });

        if (successCallback) {
          successCallback();
        }
      }).catch((err) => {
        console.error(err);

        clearJwtCache();
        dispatch({
          type: eActionTypes.SET_USER,
          payload: {},
        });

        if (errorCallback) {
          errorCallback();
        }
      });
  }
}

export function logoutUser(successCallback) {
  return function (dispatch) {
    clearJwtCache();
    dispatch({
      type: eActionTypes.SET_USER,
      payload: {},
    });

    if (successCallback) {
      successCallback();
    }
  }
}
