/**
 * Date Created: 02-07-2020
 * Author: Jose Gunawarman
 * Set Public Key & Authentication Token on header as a default for every API requests
 */
import axios from 'axios';
import * as CONFIG from '../config.json';

function setAuthorizationHeader(a_sslKey, a_token) {
  if (a_token) {
    axios.defaults.headers[CONFIG.api_ssl_header_req] = a_sslKey;
    axios.defaults.headers.common[CONFIG.api_auth_header] = `Bearer ${a_token}`;
  } else {
    delete axios.defaults.headers[CONFIG.api_ssl_header_req];
    delete axios.defaults.headers.common[CONFIG.api_auth_header];
  }
}

export default setAuthorizationHeader;
