export class KDAVacancyObject {
  private constructor(
    public readonly strID: string,
    public strTitle: string,
    public strDescription: string,
    public arrDetails: KDAVacancyDetailsObject[],
    public readonly tDatePosted: Date,
    public tDateClosing: Date,
    public readonly iApplicants: number,
  ) { };

  toString() {
    return this.strTitle;
  }
}

export class KDAVacancyDetailsObject {
  private constructor(
    public strHeading: string,
    public strText: string,
    public arrPoints: string[],
  ) { };

  toString() {
    return this.strHeading;
  }
}

export class KDAApplicantObject {
  private constructor(
    public readonly strID: string,
    public readonly strVacancyID: string,
    public strName: string,
    public strEmail: string,
    public strEmailMessage: string,
    public readonly tDateApplied: Date,
  ) { };

  toString() {
    return this.strName;
  }
}

export class KDAServerObject {
  private constructor(
    public readonly iTotalSpace: number,
    public readonly iUsedSpace: number,
    public readonly iFreeSpace: number,
    public readonly iUsedPercentage: number,
    public readonly tServerTime: Date,
  ) { };

  toString() {
    return this.iTotalSpace;
  }
}

export class KDASortByDateOption {
  private constructor(
    public strLabel: string,
    public strDatePropName: string,
    public bOldToNew: boolean,
  ) { };

  toString() {
    return this.strLabel;
  }
}
