/**
 * Date Created: 25-06-2020
 * Author: Nico Richardo
 * Reducers to store & keeping track Applicant-related data on Redux
 */
import { eActionTypes } from '../enums';
import { KDAApplicantObject } from '../interfaces';

const initialState = {
  g_arrApplicantList: [],
  g_objSelectedApplicant: new KDAApplicantObject(-1)
}

export default function (state = initialState, action) {
  switch (action.type) {
    case eActionTypes.FETCH_APPLICANTS_BY_VACANCY_ID:
      return {
        ...state,
        g_arrApplicantList: action.payload,
      };
    case eActionTypes.FETCH_APPLICANT_BY_APPLICANT_ID:
      return {
        ...state,
        g_objSelectedApplicant: action.payload,
      };
    case eActionTypes.UPDATE_APPLICANT_BY_APPLICANT_ID:
      return {
        ...state,
        g_objSelectedApplicant: action.payload,
      };
    case eActionTypes.DELETE_APPLICANT_BY_APPLICANT_ID:
      return {
        ...state,
        g_objSelectedApplicant: action.payload,
      };
    default:
      return state;
  }
}
